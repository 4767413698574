import { generatePath } from 'react-router-dom';
import { navigateToMicroApp } from '@dvag/dfs-orchestrator-client/navigation';
import { sharedAppRoutes } from '@dvag/dfs-constant-config/shared-app-names';
import { IconName } from '@dvag/design-system-icons/dist/types/types/icon-types';
import i18next from 'i18next';

import { dashboard } from 'config/asset/dashboard';
import { route } from 'config/route';
import { dashboardUtil } from 'service/model/dashboardUtil';
import { handleNavigateToPath } from 'service/handleNavigateToPath';
import { getEnvironmentConfig } from '@dvag/dfs-constant-config/app-urls';
import { NotificationErrorData } from 'utils/notification/types';
import { PageCodes } from '@dvag/dfs-constant-config';

interface GetUpperListProps {
  householdId?: string;
  personId?: string;
  showPlanning?: boolean;
  showShoppingCart?: boolean;
  showBankConnection?: boolean;
  showAnalysisEvaluation?: boolean;
  showCoachReport?: boolean;
  hasAtLeastOneConsent?: boolean;
  showAlert?: (data?: NotificationErrorData) => void;
  pageAction: (code: PageCodes) => void;
  isHouseholdDataFetched?: boolean;
}

interface LinkListData {
  display?: boolean;
  icon: IconName;
  title: string;
  onClick: () => void;
  isDisabled?: boolean;
}

interface UpperListData {
  id: string;
  image: string;
  title: string;
  subtitle: string;
  chevronIcon: IconName;
  onClick: () => void;
  display?: boolean;
  linkList?: LinkListData[];
}

const environment = getEnvironmentConfig().env;
const { ENVPROD, ENVUAT, SHOPPING_CART, PERSON, ADVISOR, ANALYSIS_EVALUATION } = dashboardUtil;

const getPlaningEnvPath = () => {
  if (environment === ENVPROD) {
    return '';
  }
  if (environment === ENVUAT) {
    return '.abnahme';
  }
  return '.integration';
};

const datenTurboLink = `https://datenturbo${getPlaningEnvPath()}.dvag`;
const { analyseDashboard } = route;

export const getUpperList = ({
  householdId,
  personId,
  showPlanning,
  showShoppingCart,
  showBankConnection,
  showAnalysisEvaluation,
  showCoachReport,
  hasAtLeastOneConsent,
  showAlert,
  pageAction,
  isHouseholdDataFetched,
}: GetUpperListProps): UpperListData[] => [
  {
    id: 'analyse',
    image: dashboard.Analyse,
    title: i18next.t('dashboard.upperList.analyse'),
    subtitle: i18next.t('dashboard.upperList.analyseSubtitle'),
    chevronIcon: 'chevron-rechts',
    onClick: () => handleNavigateToPath(analyseDashboard, householdId),
    display: true,
  },
  {
    id: 'planning',
    image: dashboard.Planning,
    title: i18next.t('dashboard.upperList.planning'),
    subtitle: i18next.t('dashboard.upperList.planningSubtitle'),
    chevronIcon: 'chevron-unten',
    onClick: () => undefined,
    display: showPlanning || showShoppingCart,
    linkList: [
      {
        display: showPlanning,
        icon: 'dokument',
        title: i18next.t('dashboard.upperList.processDocumentsLinkTitle'),
        onClick: () => window.open(datenTurboLink),
      },
      {
        display: showAnalysisEvaluation,
        icon: 'analyse-ersttermin',
        title: i18next.t('dashboard.upperList.analysisEvaluation'),
        onClick: () =>
          navigateToMicroApp(
            ANALYSIS_EVALUATION,
            generatePath('/haushalt/:householdId/assessment', { householdId: householdId || null }),
          ),
      },
      {
        display: showShoppingCart,
        icon: 'renditeorientiert',
        title: i18next.t('dashboard.upperList.product'),
        onClick: () => {
          if (!isHouseholdDataFetched) {
            return;
          }
          if (hasAtLeastOneConsent) {
            navigateToMicroApp(
              SHOPPING_CART,
              generatePath(sharedAppRoutes.person.resume, { householdId }),
            );
          } else if (showAlert) {
            showAlert({
              description: i18next.t('toastNotifications.missingConsent'),
              title: i18next.t('toastNotifications.title'),
            });
          }
        },
        isDisabled: !isHouseholdDataFetched,
      },
      {
        display: showCoachReport,
        icon: 'teilen',
        title: i18next.t('dashboard.upperList.coachReport'),
        onClick: () => pageAction('COACH_REPORT'),
      },
      {
        display: showBankConnection,
        icon: 'dokument',
        title: i18next.t('dashboard.upperList.bankConnection'),
        onClick: () => {
          handleNavigateToPath(
            generatePath('/haushalt/:householdId/bankauslese/person/:personId', {
              householdId: householdId || null,
              personId: personId || null,
            }),
            '',
            PERSON,
          );
        },
      },
    ],
  },
  {
    id: 'consultation',
    image: dashboard.Beratung,
    title: i18next.t('dashboard.upperList.beratung'),
    subtitle: i18next.t('dashboard.upperList.beratungSubtitle'),
    chevronIcon: 'chevron-rechts',
    onClick: () =>
      navigateToMicroApp(
        ADVISOR,
        generatePath(sharedAppRoutes.person.advisoryHousehold, { householdId }),
      ),
    display: true,
  },
];
