import { appNames } from '@dvag/dfs-constant-config/app-names';
import {
  DxButton,
  DxList,
  DxListItem,
  DxModal,
  DxRadioButtonGroup,
} from '@dvag/design-system-react';
import i18next from 'i18next';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { route } from 'config/route';
import { handleNavigateToPath } from 'service/handleNavigateToPath';

import type { Address } from 'type/address';
import type { GetPersonHouseholdListResponse, PersonListItem } from 'type/person';
import { getPersonFullName } from './component/household/nameList/NameList';
import { HouseholdHeader } from './component/HouseholdHeader';
import { useViewHousehold } from './hooks/useViewHousehold';
import { usePersonHousehold } from './service/usePersonHousehold';

import './style.css';

export const HouseholdWidgetPlaceholder = () => {
  const translation = {
    cancel: i18next.t('general.cancel'),
    apply: i18next.t('general.apply'),
  };
  const { selectedPersonId } = useParams();
  const { data: householdList, isLoading } = usePersonHousehold(selectedPersonId);
  const [nextHouseholdId, setNextHouseholdId] = useState<string>('');
  const mutation = useViewHousehold();
  const isModalVisible = useRef(true);
  const [selectedHouseholdId, setSelecteHouseholdId] = useState<string | null>(null);
  const [person, setPerson] = useState<PersonListItem | null>(null);

  const onModalClosed = () => {
    handleNavigateToPath(route.householdDashboard, undefined, appNames.advisor);
  };

  const viewHousehold = useCallback(
    async (id: string) => {
      mutation.viewHousehold(id);
      if (isModalVisible.current === true) {
        isModalVisible.current = false;
      }
    },
    [mutation],
  );

  const getPersonListFullName = (personList: PersonListItem[]) =>
    personList
      .sort((a, b) => a.order - b.order)
      .map((hhPerson) => getPersonFullName(hhPerson))
      .join(', ');

  const getAddress = ({ country, street, zipCode, city }: Address) => {
    const streetText = street ? `${street}, ` : '';
    const zipCodeText = zipCode ? `${zipCode} ` : '';
    const cityText = city ? `${city}, ` : '';
    const countryText = country ? `${country}` : '';

    return `${streetText}${zipCodeText}${cityText}${countryText}`;
  };

  const getPersonSublabel = (household: GetPersonHouseholdListResponse) => {
    if (!household.address) return '';
    return `${getAddress(household.address)} \n ${i18next.t('dashboard.personsInHousehold', {
      personList: household.personInHouseholdList.length,
    })}`;
  };

  const onSubmit = () => {
    if (nextHouseholdId) {
      setSelecteHouseholdId(nextHouseholdId);
    }
  };

  useEffect(() => {
    if (isLoading) return;
    if (householdList.length === 1) {
      if (householdList[0].id !== selectedHouseholdId) {
        setSelecteHouseholdId(householdList[0].id);
      }
    } else if (householdList.length > 1) {
      const selectedPerson = householdList[0].personInHouseholdList.find(
        ({ id: personId }) => personId === selectedPersonId,
      );
      if (selectedPerson) {
        setPerson(selectedPerson);
      }
    }
  }, [householdList, selectedPersonId, viewHousehold, isLoading, selectedHouseholdId]);

  useEffect(() => {
    if (selectedHouseholdId) {
      viewHousehold(selectedHouseholdId);
    }
  }, [selectedHouseholdId, viewHousehold]);

  const personFullName = getPersonFullName(person);
  const isVisible = !!(householdList.length > 1 && isModalVisible.current);
  return (
    <>
      <HouseholdHeader household={undefined} personList={[]} selectedHouseholdId={undefined} />
      <DxModal
        visible={`${isVisible}` as unknown as boolean}
        id="comment-modal"
        height="content"
        label={personFullName}
        onModalClosed={onModalClosed}
        data-testid="household-widget-placeholder-container"
      >
        <div slot="content">
          <DxRadioButtonGroup
            id="household-widget-radio-button-group"
            data-testid="household-widget-radio-button-group"
            onValueChange={(event) => setNextHouseholdId(event.target.value)}
          >
            <DxList>
              {householdList.map((household) => (
                <DxListItem
                  data-testid="household-widget-placeholder-list-item"
                  value={household.id}
                  key={household.id}
                  id={household.id}
                  type="radio"
                  label={getPersonListFullName(household.personInHouseholdList)}
                  sublabel={getPersonSublabel(household)}
                />
              ))}
            </DxList>
          </DxRadioButtonGroup>
        </div>
        <DxButton
          id="cancel-button"
          slot="secondary-actions"
          type="text"
          label={translation.cancel}
          onClick={onModalClosed}
        />
        <DxButton
          id="apply-button"
          data-testid="household-widget-apply-button"
          slot="primary-actions"
          type="primary-m"
          label={translation.apply}
          onClick={onSubmit}
        />
      </DxModal>
    </>
  );
};
