import { useMutation, useQueryClient } from '@tanstack/react-query';
import { appointmentApi } from 'service/api/appointmentApi';
import { useNotification } from 'utils/notification/NotificationContext';
import { CustomError } from '@dvag/dfs-api-client/error';
import { Feedback } from '../type/advisor';
import { getFeedbackKey } from './useFeedback';

interface PropTypes {
  householdId: string;
  appointmentId?: string;
}

export const useUpdateFeedback = ({ householdId, appointmentId }: PropTypes) => {
  const { showError } = useNotification();
  const queryClient = useQueryClient();
  const appointmentService = appointmentApi();
  return useMutation<Feedback, CustomError, Feedback, unknown>({
    mutationFn: async (values: Feedback) => {
      const result = await appointmentService.patchFeedback({
        householdId,
        id: values.id,
        preImpression: values.preImpression,
        postImpression: values.postImpression,
        postImpressionComment: values.postImpressionComment || '',
        futureWishes: values.futureWishes || '',
        recommendedInterestedParties: values.recommendedInterestedParties || '',
      });

      return result.data;
    },
    onSuccess: (data) => {
      const queryKey = getFeedbackKey(householdId, appointmentId);
      queryClient.setQueryData(queryKey, data);
    },
    onError: (error) => {
      showError({ status: error?.status });
    },
  });
};
