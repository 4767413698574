export const isNameListContain = (names: string[] = [], firstName = '', lastName = '') => {
  if (!names || names.length === 0) {
    return false;
  }

  const first = (firstName || '').toLowerCase();
  const last = (lastName || '').toLowerCase();

  return names.some(
    (name) => (first && first.indexOf(name) !== -1) || (last && last.indexOf(name) !== -1),
  );
};
