import { getAppInsights } from './getAppInsights';

export const sessionStoreKeys = {
  isQuickAccessOpen: 'isQuickAccessOpen',
};

export const getSessionStore = <T>(key: string) => {
  const { appInsights } = getAppInsights();

  const getItem = () => {
    try {
      const storageItem = sessionStorage.getItem(key);
      return storageItem ? (JSON.parse(storageItem) as T) : null;
    } catch (e) {
      appInsights.trackException({
        exception: new Error(`${key} value in localStorage not a valid JSON. ${e}`),
        severityLevel: 3,
      });
    }
    return null;
  };

  const setItem = (value: T) => {
    sessionStorage.setItem(key, JSON.stringify(value));
  };

  const removeItem = () => sessionStorage.removeItem(key);

  const extendItem = (value: T) => {
    const existingItem = getItem();
    if (!existingItem) {
      setItem(value);
    } else {
      const newItem = { ...existingItem, ...value };
      setItem(newItem);
    }
  };

  return {
    getItem,
    setItem,
    extendItem,
    removeItem,
  };
};
