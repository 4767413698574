import APIClient from '@dvag/dfs-api-client';
import { getContext, getToken } from '@dvag/dfs-orchestrator-client';
import { getEnvironmentApiUrls } from '@dvag/dfs-constant-config/api-urls';

const config = { baseURL: `${getEnvironmentApiUrls().kiVp}` };
const api = new APIClient({
  config,
  options: {
    getToken,
    getContext,
    hasDefaultRequestInterceptors: true,
  },
});

export const kivpApi = () => {
  const getUrlFromModule = async (moduleType: string) =>
    api.get<string>(`/one-note?moduleType=${moduleType}`);

  return {
    getUrlFromModule,
  };
};
