import { MutationKey } from '@tanstack/react-query';
import { IconName } from '@dvag/design-system-icons/dist/types/types/icon-types';
import { getToken } from '@dvag/dfs-orchestrator-client';
import { jwtDecode } from 'jwt-decode';

import i18next from 'i18next';
import { consentStatus } from '../service/model/consentStatus';

import { PersonFullName } from '../type/person';
import { TransformParameters } from '../type/taxData';
import { AccessToken } from '../type/advisor';

const { APPROVED } = consentStatus;

export const queryKeyStr: MutationKey = ['contact-information-'];

export const xl = 64;

export const familyRelationshipList = {
  KIND: 'KIND',
  ERWACHSEN: 'ERWACHSEN',
};

export const hasApprovedConsent = (status: string): boolean => !!status && status === APPROVED;

export const getPersonFullName = ({ firstName, lastName }: PersonFullName): string =>
  `${firstName} ${lastName}`;

export const transformEmptyToNull = (obj: TransformParameters): TransformParameters => {
  if (obj === '' || obj === null) {
    return null;
  }
  if (typeof obj !== 'object') {
    return obj;
  }
  return Object.entries(obj).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: transformEmptyToNull(value),
    }),
    {},
  );
};

export const removeEmptySpace = <T>(value: T): string | T =>
  typeof value === 'string' ? value.trim() : value;

export const checkIcon = (icon: IconName): IconName => icon;

export const getConsentStatusTranslation = (key: string) => {
  switch (key) {
    case 'created':
      return i18next.t(`dashboard.gdpr.status.created`);
    case 'approved':
      return i18next.t(`dashboard.gdpr.status.approved`);
    case 'error':
      return i18next.t(`dashboard.gdpr.status.error`);
    case 'sentOut':
      return i18next.t(`dashboard.gdpr.status.sentOut`);
    case 'outdated':
      return i18next.t(`dashboard.gdpr.status.outdated`);
    default:
      return i18next.t(`dashboard.gdpr.status.missing`);
  }
};

export const { aussendienst, assistent, innendienst } = {
  aussendienst: 'AUSSENDIENST',
  innendienst: 'INNENDIENST',
  assistent: 'ASSISTENT',
};

export const isUserAssistant = () => {
  const accessToken = getToken();
  const token: AccessToken = jwtDecode(JSON.stringify(accessToken));

  return !!token.context;
};
