import APIClient from '@dvag/dfs-api-client';
import { getContext, getToken } from '@dvag/dfs-orchestrator-client';

import { HouseholdLastAccessedResponse } from 'type/household';
import { TransformParameters } from 'type/taxData';
import {
  CommunicationChannelSectionDataType,
  ConsentPayload,
  GetPersonHouseholdListResponse,
  PersonSearchItem,
} from 'type/person';
import { BasicDataPayload } from 'type/basicData';
import { getEnvironmentApiUrls } from '@dvag/dfs-constant-config/api-urls';

import {
  advisorHasEnteredHousehold,
  consentHasBeenUpdated,
} from '@dvag/dfs-orchestrator-client/messengers';
import { PersonDto } from 'graphql/person/generates';
import { versions } from './versions';

type CommunicationChannelPayload = CommunicationChannelSectionDataType | TransformParameters;

const config = { baseURL: `${getEnvironmentApiUrls().person}/${versions.v3}` };
const api = new APIClient({
  config,
  options: {
    getToken,
    getContext,
    hasDefaultRequestInterceptors: true,
  },
});

export const personApi = () => {
  const updatePerson = async (personId: string, payload: BasicDataPayload) => {
    const interceptorNr = await api.addResponseInterceptor((value) => {
      const advisorId = getContext()?.user.id;
      consentHasBeenUpdated(advisorId, payload.householdId as string, personId);
      return value;
    });
    const response = await api.put<PersonDto, BasicDataPayload>(`/person/${personId}`, payload);
    await api.removeResponseInterceptor(interceptorNr);
    return response;
  };

  const updateLastAccessedHouseholdList = async (householdId: string) => {
    const interceptorNr = await api.addResponseInterceptor((value) => {
      const advisorId = getContext()?.user.id;
      advisorHasEnteredHousehold(advisorId, householdId);
      return value;
    });
    const response = await api.put<never, never[]>(`/household/${householdId}/last-accessed`, []);
    await api.removeResponseInterceptor(interceptorNr);
    return response;
  };

  const getLastAccessed = async () =>
    api.get<HouseholdLastAccessedResponse[]>('/household/last-accessed');

  const getSearchPerson = async (queryParam: string) => {
    const params = new URLSearchParams();
    params.append('query', queryParam);

    return api.get<PersonSearchItem[]>(`/person/search?${params}`);
  };

  const getPersonHouseholdList = async (personId: string | undefined) =>
    api.get<GetPersonHouseholdListResponse[]>(`/person/${personId}/household`);

  const createCommunicationChannel = async (
    payload: CommunicationChannelPayload,
    personId: string | undefined,
  ) =>
    api.post<
      {
        context: string;
        id: string;
        type: string;
        value: string;
      },
      CommunicationChannelPayload
    >(`/person/${personId}/communication-channel`, payload);

  const updateCommunicationChannel = async (
    id: string,
    payload: CommunicationChannelPayload,
    personId: string | undefined,
  ) =>
    api.put<
      {
        context: string;
        id: string;
        prefix: string;
        type: string;
        value: string;
      },
      CommunicationChannelPayload
    >(`/person/${personId}/communication-channel/${id}`, payload);

  const createConsent = async (personId: string, payload: ConsentPayload) =>
    api.post<
      {
        email: string;
        link: string;
        personId: string;
        status: string;
        type: string;
      },
      ConsentPayload
    >(`/person/${personId}/consent`, payload);

  return {
    updatePerson,
    updateLastAccessedHouseholdList,
    getLastAccessed,
    getSearchPerson,
    createCommunicationChannel,
    updateCommunicationChannel,
    createConsent,
    getPersonHouseholdList,
  };
};
