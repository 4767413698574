import { getEnvironmentAppUrls } from '@dvag/dfs-constant-config/app-urls';
import { getContext } from '@dvag/dfs-orchestrator-client';
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';

import i18next from 'i18next';

import { AuthAdapter, AuthAdapterInterface } from 'service/AuthAdapter';
import { consentStatus } from 'service/model/consentStatus';
import { SectionListData } from 'type/sectionList';

import { gdprIcons } from './GdprIcon';

export const borderBottom = '1px solid #DEDEDE';
const kundezugangTag = 'kundenzugang-webcomponent';
const webComponentUrl = getEnvironmentAppUrls().kundenzugang;

const kundenzugangSetAttribute = ({
  existentKundenzugang,
  isTwoPersonsOlderEighteen,
  householdId,
}: {
  existentKundenzugang: Element & { authenticator?: AuthAdapterInterface };
  isTwoPersonsOlderEighteen?: boolean;
  householdId: string;
}) => {
  if (existentKundenzugang) {
    existentKundenzugang.setAttribute('haushaltsid', householdId);
    if (isTwoPersonsOlderEighteen) {
      existentKundenzugang.setAttribute('familienfreigabe', 'true');
    }
    existentKundenzugang.authenticator = AuthAdapter();
  }
};

export const useUtil = (
  householdId: string,
  isTwoPersonsOlderEighteen = false,
): {
  disabledBottomList: SectionListData[];
  bottomList: SectionListData[];
  hhbContract: SectionListData[];
  openKundenzugangTool: () => void;
  isKundenzugangToolClosed: boolean;
  setIsKundenzugangToolClosed: Dispatch<SetStateAction<boolean>>;
  customerDetail: { kundenNr: string; keycloakId: string };
} => {
  const [isKundenzugangToolClosed, setIsKundenzugangToolClosed] = useState<boolean>(false);
  const [customerDetail, setCustomerDetail] = useState<{ kundenNr: string; keycloakId: string }>({
    kundenNr: '',
    keycloakId: '',
  });
  const kundenzugangPermission = getContext()?.user?.permissions.includes(
    'permission.keycloak.client.app-web-idm-kundenzugang.user',
  );
  const injectKundezugang = useCallback(() => {
    const head = document.head || document.getElementsByTagName('head')[0];
    const existentWidget = document.getElementById('kundenzugang-url');
    if (!existentWidget) {
      const widget = document.createElement('script');
      widget.id = 'kundenzugang-url';
      widget.src = webComponentUrl;
      head.appendChild(widget);
    }

    const existentKundenzugang: Element & { authenticator?: AuthAdapterInterface } =
      document.getElementsByTagName(kundezugangTag)[0];
    kundenzugangSetAttribute({ existentKundenzugang, householdId, isTwoPersonsOlderEighteen });
  }, [householdId, isTwoPersonsOlderEighteen]);
  useEffect(() => {
    if (kundenzugangPermission) {
      injectKundezugang();
    }
  }, [kundenzugangPermission, injectKundezugang, householdId]);

  document.addEventListener('idm-kundenzugang-component-familienfreigabe', (event) => {
    const customEvent = event as CustomEvent;
    if (customEvent.detail.keycloakId) {
      setCustomerDetail({
        kundenNr: customEvent.detail.kundenNr,
        keycloakId: customEvent.detail.keycloakId,
      });
    }
  });

  document.addEventListener('idm-kundenzugang-component', (event) => {
    const customEvent = event as CustomEvent;
    setIsKundenzugangToolClosed(customEvent.detail.complete);
  });

  const openKundenzugangTool = useCallback(() => {
    const kundenzugangTool = document.getElementsByTagName(kundezugangTag)[0] as unknown as {
      openModal: () => void;
    };
    if (kundenzugangTool?.openModal) {
      kundenzugangTool.openModal();
    }
  }, []);

  const disabledBottomList: SectionListData[] = [
    {
      borderBottom,
      icon: 'risiko-minimieren',
      title: i18next.t('dashboard.bottomList.gdpr'),
      path: '',
      id: 'privacy-policy-disabled',
    },
    {
      borderBottom,
      icon: 'immobilienfinanzierung',
      title: i18next.t('dashboard.bottomList.hhbAndContractOverview'),
      path: '',
      id: 'hhb-and-contract-overview-disabled',
    },
    {
      borderBottom,
      icon: 'kalender',
      title: i18next.t('dashboard.bottomList.appointment'),
      path: '',
      id: 'interview-conclusion-disabled',
    },
  ];

  const bottomList: SectionListData[] = [
    {
      borderBottom,
      icon: 'absicherung',
      title: i18next.t('dashboard.bottomList.gdpr'),
      path: 'datenschutzbestimmungen',
      id: 'privacy-policy',
    },
    {
      borderBottom,
      icon: 'immobilienfinanzierung',
      title: i18next.t('dashboard.bottomList.hhbAndContractOverview'),
      path: 'hhb-und-vertragsuebersicht',
      id: 'hhb-and-contract-overview',
    },
    {
      borderBottom,
      icon: 'kalender',
      title: i18next.t('dashboard.bottomList.appointment'),
      path: 'interview-conclusion',
      id: 'interview-conclusion',
    },
  ];

  const hhbContract: SectionListData[] = [
    {
      borderBottom,
      icon: 'immobilienfinanzierung',
      title: i18next.t('dashboard.bottomList.householdBudget'),
      path: '/haushalt/:householdId/analyse/haushaltsbudget/ausgaben',
      id: 'hhb',
    },
    {
      icon: 'klemmbrett',
      title: i18next.t('dashboard.bottomList.contract'),
      path: '/haushalt/:householdId/analyse/haushaltsbudget/vertragsuebersicht',
      id: 'contract-overview',
    },
  ];

  return {
    disabledBottomList: kundenzugangPermission
      ? [
          ...disabledBottomList,
          {
            icon: 'mensch',
            title: i18next.t('dashboard.bottomList.kundenzugang'),
            path: '',
            id: 'kundenzugang',
          },
        ]
      : disabledBottomList,
    bottomList: kundenzugangPermission
      ? [
          ...bottomList,
          {
            icon: 'mensch',
            title: i18next.t('dashboard.bottomList.kundenzugang'),
            path: 'kundenzugang',
            id: 'kundenzugang',
          },
        ]
      : bottomList,
    hhbContract,
    openKundenzugangTool,
    isKundenzugangToolClosed,
    setIsKundenzugangToolClosed,
    customerDetail,
  };
};

const { APPROVED, SENT, EMPTY } = consentStatus;

const { ApprovedGdpr, PendingApproval, NotSyncedGdpr } = gdprIcons;

const DecriptionKeyEnum = {
  [APPROVED]: 'approved',
  [SENT]: 'sentOut',
  [EMPTY]: 'missing',
};

const { [APPROVED]: APPROVED_DESC, [SENT]: SENT_DESC, [EMPTY]: EMPTY_DESC } = DecriptionKeyEnum;

const consentStatusMapping = {
  [APPROVED]: { Icon: ApprovedGdpr, descriptionKey: APPROVED_DESC },
  [SENT]: { Icon: PendingApproval, descriptionKey: SENT_DESC },
  [EMPTY]: { Icon: NotSyncedGdpr, descriptionKey: EMPTY_DESC },
};

export const getConsentStatus = (status: string | undefined) =>
  status && consentStatusMapping[status];

export const isPersonYoungerThan18 = (birthdate?: string | null): boolean => {
  if (!birthdate) {
    return true;
  }
  const birthdateDate = new Date(birthdate);
  const currentDate = new Date();

  const age = currentDate.getFullYear() - birthdateDate.getFullYear();
  const adultAge = 18;

  if (
    currentDate.getMonth() < birthdateDate.getMonth() ||
    (currentDate.getMonth() === birthdateDate.getMonth() &&
      currentDate.getDate() < birthdateDate.getDate())
  ) {
    return age - 1 < adultAge;
  }

  return age < adultAge;
};
