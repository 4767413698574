import { PersonalEmail } from 'service/model/communicationType';
import { CommunicationChannelSectionDataType, PersonConsentData } from 'type/person';
import { CommunicationChannelCurrentEmail } from 'type/contact';

export const handleDataBeforeSubmit = (
  householdId: string,
  personId: string,
  data: PersonConsentData,
  currentEmailData: CommunicationChannelCurrentEmail,
  dirtyFields: { email?: boolean; birthDate?: boolean },
): CommunicationChannelSectionDataType[] => {
  const emailId = currentEmailData?.id;
  const createEmailPayload = {
    type: PersonalEmail,
    value: data.email,
    personId,
    householdId,
  };

  const updateEmailPayload = {
    type: currentEmailData?.type,
    value: data.email,
    id: currentEmailData?.id,
    personId,
    householdId,
  };

  const birthDatePayload = {
    householdId,
    birthDate: data.birthDate || '',
  };

  const emailPayload = emailId ? updateEmailPayload : createEmailPayload;

  if (dirtyFields?.email && !dirtyFields?.birthDate) {
    return [emailPayload];
  }
  if (!dirtyFields?.email && dirtyFields?.birthDate) {
    return [birthDatePayload];
  }
  return [emailPayload, birthDatePayload];
};

export const defaultConsentData = {
  email: '',
  birthDate: null,
};
