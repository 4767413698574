import { DxButton, DxDateInput, DxModal, DxText, DxTextInput } from '@dvag/design-system-react';
import { appNames } from '@dvag/dfs-constant-config/app-names';
import { useQueryClient } from '@tanstack/react-query';
import i18next from 'i18next';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { mandatoryFieldMessage } from 'form/util';
import { validateEmail } from 'form/validation';
import { CommunicationChannelType } from 'graphql/person/generates';
import { useCreateOrUpdateCommunicationChannel } from 'hooks/useCreateOrUpdateCommunicationChannel';
import { useSendConsent } from 'hooks/useSendConsent';
import { useUpdatePersonConsentData } from 'hooks/useUpdatePersonConsentData';
import { consentStatus } from 'service/model/consentStatus';
import { consentType as consentTypes } from 'service/model/consentType';
import { FieldType } from 'utils/fieldList';
import { getPersonFullName, removeEmptySpace, xl } from 'utils/util';
import { formatDate, validateConsentDate } from 'utils/validation';

import type { ConsentDto, PersonInHouseholdDto, PersonStatus } from 'graphql/person/generates';
import type { BaseSyntheticEvent } from 'react';
import { isPersonYoungerThan18 } from 'screen/HouseholdDashboard/component/BottomCard/util';
import type { BasicDataConsentPayload } from 'type/basicData';
import type { PersonConsentData } from 'type/person';
import { ModalHeader } from './component/ModalHeader';
import { defaultConsentData, handleDataBeforeSubmit } from './handleData';
import { getConsentDataQueryKey, useGetConsentModalData } from './useGetConsentModalData';

import { getAppInsights } from '../../service/getAppInsights';
import './style.css';

interface ConsentModalPropList {
  visible: boolean;
  person: PersonInHouseholdDto;
  consent?: ConsentDto;
  isConsentFetching: boolean;
  onSubmitSuccess?: () => void;
  personConsentStatus: string;
  setIsConsentEmailSent: (bool: string) => void;
  onOpened?: () => void;
  onClosed?: () => void;
}

type ConsentType = 'email' | 'signature' | undefined | BaseSyntheticEvent<object>;

export const ConsentModal = ({
  visible,
  person,
  consent,
  isConsentFetching,
  personConsentStatus,
  setIsConsentEmailSent,
  onSubmitSuccess,
  onOpened,
  onClosed,
}: ConsentModalPropList) => {
  const { householdId } = useParams();
  const [currentEmailData, setCurrentEmailData] = useState<{
    id: string;
    type: CommunicationChannelType;
  } | null>(null);
  const [consentType, onConsentTypeChange] = useState('');

  const [currentEmailValue, setCurrentEmailValue] = useState('');
  const [currentBirthDate, setCurrentBirthDate] = useState('');
  const [isSignatureDisabled, setIsSignatureDisabled] = useState(false);
  const [personStatus, setPersonStatus] = useState<PersonStatus | null>(null);

  const queryClient = useQueryClient();

  const updatePersonConsentData = useUpdatePersonConsentData(person.id);
  const createOrUpdateCommunicationChannel = useCreateOrUpdateCommunicationChannel(person.id);

  const sendConsent = useSendConsent({
    householdId: householdId as string,
    personId: person.id,
  });

  const { data, isFetched: isDataFetched } = useGetConsentModalData(
    person.id,
    householdId as string,
  );

  const isSubmitting =
    updatePersonConsentData.isPending ||
    createOrUpdateCommunicationChannel.isPending ||
    sendConsent.isPending;

  const { handleSubmit, reset, getValues, formState, setValue, register, trigger } =
    useForm<PersonConsentData>({
      values: {
        birthDate: currentBirthDate.length ? currentBirthDate : person?.birthDate,
        email: currentEmailValue || '',
      },
      mode: 'onChange',
      reValidateMode: 'onChange',
      defaultValues: defaultConsentData,
    });

  const clearForm = useCallback(() => {
    onConsentTypeChange?.('');
    setCurrentEmailData(null);
    setCurrentEmailValue('');
    reset(defaultConsentData);
    setIsSignatureDisabled(false);
  }, [onConsentTypeChange, reset]);

  const setMainEmail = useCallback(() => {
    const cChannel = data?.person.communicationChannel || null;
    if (cChannel && cChannel.length) {
      const emailChannel = cChannel.find(
        ({ type }) => type === CommunicationChannelType.EMailPrivat,
      );
      if (emailChannel) {
        const { id, type, value } = emailChannel;
        setCurrentEmailData({ id, type });
        setCurrentEmailValue(value);
      }
    } else {
      setCurrentEmailData(null);
    }
  }, [data]);

  const establishTypeOfConsent = useCallback(
    (buttonType: ConsentType) => {
      setIsSignatureDisabled(true);
      return buttonType === consentTypes.SIGNATURE
        ? onConsentTypeChange?.(consentTypes.SIGNATURE)
        : onConsentTypeChange?.(consentTypes.EMAIL);
    },
    [onConsentTypeChange],
  );

  const { appInsights } = getAppInsights();

  const { errors, isValid, dirtyFields } = formState;

  const onChange = (value: string, fieldName: 'email' | 'birthDate') => {
    setValue(fieldName, removeEmptySpace<string | undefined>(value), {
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  useEffect(() => {
    if (visible && data) {
      setPersonStatus(data.person.basicData?.status || null);
    }
  }, [visible, data]);

  useEffect(() => {
    if (visible && isDataFetched) {
      setMainEmail();
    }
  }, [visible, isDataFetched, setMainEmail]);

  useEffect(() => {
    setCurrentBirthDate(person?.birthDate || '');
  }, [person]);

  const onSubmit = useCallback(
    async (personConsentData: PersonConsentData, buttonType: ConsentType) => {
      if (isValid) {
        establishTypeOfConsent(buttonType);
        const payload = handleDataBeforeSubmit(
          householdId as string,
          person.id,
          personConsentData,
          {
            id: currentEmailData?.id,
            type: currentEmailData?.type,
          },
          dirtyFields,
        );
        const requests = payload.map(async (payloadData) => {
          if (payloadData?.value) {
            try {
              reset(personConsentData, { keepDirty: false });
              await createOrUpdateCommunicationChannel.mutateAsync(payloadData);
              queryClient.invalidateQueries({ queryKey: getConsentDataQueryKey(person.id) });
            } catch (err) {
              appInsights.trackException({
                exception: err as Error,
                severityLevel: 3,
              });
              console.error(err);
            }
          }
          if (payloadData?.birthDate && personStatus !== 'KUNDE' && personStatus !== 'ALTKUNDE') {
            try {
              reset(personConsentData, { keepDirty: false });
              setCurrentBirthDate(payloadData.birthDate);
              await updatePersonConsentData.mutateAsync({
                ...data?.person.basicData,
                ...payloadData,
              } as BasicDataConsentPayload);
              queryClient.invalidateQueries({ queryKey: getConsentDataQueryKey(person.id) });
            } catch (err) {
              appInsights.trackException({
                exception: err as Error,
                severityLevel: 3,
              });
              console.error(err);
            }
          }
          return null;
        });
        await Promise.all(requests);
        if (buttonType === consentTypes.EMAIL) {
          try {
            await sendConsent.mutateAsync({});
            setIsConsentEmailSent('success');
          } catch (err) {
            appInsights.trackException({
              exception: err as Error,
              severityLevel: 3,
            });
            console.error(err);
          } finally {
            await queryClient.refetchQueries({ queryKey: ['getConsentData'] });
            onSubmitSuccess?.();
          }
        }
        if (buttonType === consentTypes.SIGNATURE) {
          const callbackLink = `${document.referrer}/${appNames.advisor}${window.location.pathname}`;
          const consentResponse = await sendConsent.mutateAsync({ callbackLink });
          const successfulProcessRedirectLink = consentResponse.data.link;
          if (successfulProcessRedirectLink) {
            window.parent.location.replace(successfulProcessRedirectLink);
            onSubmitSuccess?.();
          }
        }
      } else {
        trigger();
      }
    },
    [
      isValid,
      establishTypeOfConsent,
      householdId,
      person.id,
      currentEmailData?.id,
      currentEmailData?.type,
      dirtyFields,
      personStatus,
      reset,
      createOrUpdateCommunicationChannel,
      queryClient,
      appInsights,
      updatePersonConsentData,
      data?.person.basicData,
      sendConsent,
      setIsConsentEmailSent,
      onSubmitSuccess,
      trigger,
    ],
  );

  const currentDate = formatDate(new Date());

  const translation = {
    note: i18next.t('sendConsent.note'),
    redirectInProgress: i18next.t('sendConsent.redirectInProgress'),
    emailIsSent: i18next.t('sendConsent.emailIsSent'),
    question: i18next.t('sendConsent.question'),
    title: i18next.t('sendConsent.title'),
    birthDate: i18next.t('basicData.birthDate'),
    email: i18next.t('contact.email'),
    buttonSignature: i18next.t('sendConsent.buttonSignature'),
    buttonByEmail: i18next.t('sendConsent.buttonByEmail'),
  };

  const resetCalendarError = () => {
    const dateInput = document.querySelector('dx-date-input') as HTMLDxDateInputElement;
    if (dateInput?.focusControl) {
      dateInput?.focusControl();
    }
  };

  const onModalOpened = () => {
    const email = currentEmailValue || getValues(FieldType.email) || '';
    reset({ birthDate: currentBirthDate || null, email });
    setIsConsentEmailSent('');
    onOpened?.();
  };

  const onModalClosed = () => {
    onClosed?.();
    clearForm();
    setIsConsentEmailSent('');
    resetCalendarError();
  };

  const isEmailSent = () => {
    const currentEmail = getValues(FieldType.email);

    if (!currentEmail || errors?.email?.message) return false;

    const lastEmail = consent?.email || '';

    return (
      (personConsentStatus === consentStatus.SENT && currentEmail === lastEmail) ||
      (personConsentStatus === consentStatus.APPROVED && currentEmail === lastEmail)
    );
  };

  const title = getPersonFullName({
    firstName: person.firstName,
    lastName: person.lastName,
  });

  const isYoungerThan18 = isPersonYoungerThan18(getValues(FieldType.birthDate));

  return (
    <DxModal
      visible={visible}
      label={translation.title}
      height="content"
      color="headline"
      data-testid="gdpr-modal-content"
      onModalClosed={onModalClosed}
      onModalOpened={onModalOpened}
      id="m1"
    >
      <div slot="content" style={{ padding: 0 }}>
        <ModalHeader subtitle={i18next.t('sendConsent.subtitle')} name={title} />
        <form onSubmit={handleSubmit(onSubmit)} className="consent-form flex column">
          <div>
            <div className="consent-form-container">
              <DxDateInput
                size="m"
                data-testid="birthDate"
                id="birthDate"
                errormessage={errors?.birthDate?.message}
                disabled={isSubmitting || personStatus === 'KUNDE' || personStatus === 'ALTKUNDE'}
                required
                label={translation.birthDate}
                onValueChange={(e) => {
                  const { value } = e.target;
                  onChange(value, FieldType.birthDate);
                }}
                value={getValues(FieldType.birthDate) as string}
                ref={
                  register(FieldType.birthDate, {
                    required: mandatoryFieldMessage,
                    validate: (value) => validateConsentDate(value, currentDate),
                  }).ref
                }
              />
              <DxTextInput
                size="m"
                placeholder={i18next.t('general.pleaseEnter')}
                className="pd_contact_value"
                data-testid="email"
                disabled={isSubmitting}
                id="email"
                ref={
                  register(FieldType.email, {
                    validate: validateEmail,
                    maxLength: xl,
                    required: mandatoryFieldMessage,
                  }).ref
                }
                onValueChange={(event) => {
                  onChange(event.detail, FieldType.email);
                }}
                required
                value={getValues(FieldType.email)}
                label={translation.email}
                errormessage={errors?.email?.message}
              />
            </div>
            <div className="flex column consent-button-container">
              <div className="modal-header-container flex column align-center">
                <div className="consent-note-container">
                  {consentType === consentTypes.SIGNATURE && (
                    <DxText type="it" color="headline" data-testid="redirect_in_progress">
                      {translation.redirectInProgress}
                    </DxText>
                  )}
                  {consentType === consentTypes.EMAIL && (
                    <DxText type="it" color="headline" data-testid="send_email_in_progress">
                      {translation.emailIsSent}
                    </DxText>
                  )}

                  {consentType === '' && (
                    <DxText type="it" color="gray-50" data-testid="gdpr-modal-question">
                      {translation.question}
                    </DxText>
                  )}
                </div>
              </div>
              <DxButton
                type="primary-m"
                stretch
                label={translation.buttonSignature}
                id="gdpr-by-signature"
                data-testid="gdpr-by-signature"
                disabled={isSignatureDisabled || isSubmitting || isYoungerThan18}
                onClick={() => onSubmit(getValues(), consentTypes.SIGNATURE as ConsentType)}
              />
              <DxButton
                type="text"
                stretch
                label={translation.buttonByEmail}
                id="gdpr-by-email"
                data-testid="gdpr-by-email"
                disabled={
                  isSubmitting ||
                  isConsentFetching ||
                  isEmailSent() ||
                  isYoungerThan18 ||
                  !getValues(FieldType.email)
                }
                onClick={() => onSubmit(getValues(), consentTypes.EMAIL as ConsentType)}
              />
            </div>
          </div>
        </form>
      </div>
    </DxModal>
  );
};
