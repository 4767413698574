import { errorMessage as contactErrorMessage } from './util';

export const emailRegExp = /^[a-z0-9+_.-]+@(?:[a-ž0-9+_-]+?\.)+[a-z0-9](?:[a-z]+?)$/i;

export const validateEmail = (value: string) => {
  if (value === '') {
    return true;
  }
  if (value?.length && emailRegExp.test(value)) {
    return true;
  }
  return contactErrorMessage;
};
