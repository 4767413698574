import APIClient from '@dvag/dfs-api-client';
import { getContext, getToken } from '@dvag/dfs-orchestrator-client';
import { getEnvironmentApiUrls } from '@dvag/dfs-constant-config/api-urls';

import { Appointment, Booking, Feedback, FeedbackRequestBody } from 'type/advisor';
import { AppointmentPayload } from 'type/appointment';
import { versions } from './versions';

const config = { baseURL: `${getEnvironmentApiUrls().appointment}/${versions.v2}` };
const api = new APIClient({
  config,
  options: {
    getToken,
    getContext,
    hasDefaultRequestInterceptors: true,
  },
});

export const appointmentApi = () => {
  const getBooking = async () => api.get<Booking>(`/booking`);

  const getAppointment = async ({ householdId, type }: { householdId: string; type: string }) =>
    api.get<Appointment[]>(`/appointment?householdId=${householdId}&status=OPEN&type=${type}`);

  const postAppointment = async (requestBody: AppointmentPayload) =>
    api.post<Appointment, AppointmentPayload>(`appointment`, requestBody);

  const patchAppointment = async ({
    id,
    householdId,
    personId,
    startDateTime,
    type,
  }: AppointmentPayload) =>
    api.patch<Appointment, Omit<AppointmentPayload, 'id' | 'date' | 'time'>>(`appointment/${id}`, {
      householdId,
      personId,
      startDateTime,
      type,
    });

  const getFeedback = async ({
    appointmentId,
    householdId,
  }: {
    appointmentId?: string;
    householdId?: string;
  }) => api.get<Feedback>(`/feedback?appointmentId=${appointmentId}&householdId=${householdId}`);

  const patchFeedback = async ({ id, ...params }: FeedbackRequestBody) =>
    api.patch<Feedback, FeedbackRequestBody>(`/feedback/${id}`, params);

  const postFeedback = async (requestBody: FeedbackRequestBody) =>
    api.post<Feedback, FeedbackRequestBody>(`/feedback`, requestBody);

  return {
    getBooking,
    getAppointment,
    getFeedback,
    patchFeedback,
    postFeedback,
    postAppointment,
    patchAppointment,
  };
};
